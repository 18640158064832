import { useI18n } from 'vue-i18n';
import type { CtxProduct } from '@containex/portal-backend-api-client';
import { getLogger } from '@/logger/logger';

export function getProductArticleLineTranslation(articleLine: string | null | undefined): string | null {
    const { t } = useI18n();

    switch (articleLine) {
        case 'C':
            return t('PRODUCT.ARTICLE_LINE.C');
        case 'B':
            return t('PRODUCT.ARTICLE_LINE.B');
        case 'P':
            return t('PRODUCT.ARTICLE_LINE.P');
        case 'MB':
            return t('PRODUCT.ARTICLE_LINE.MB');
        case 'X':
            return null;
        case undefined:
        case null:
        default:
            return null;
    }
}

export function getProductDimension(product: CtxProduct | null): string | null {
    if (product == null) {
        const logger = getLogger('productDetailTranslation');
        logger.warn('productDetailTranslation', 'trying to get dimensions of a product, but the product is null');
        return null;
    }

    const length = product.length;
    const width = product.width;
    const height = product.height;

    return length != null && width != null && height != null
        ? `${String(length)}mm x ${String(width)}mm x ${String(height)}mm`
        : null;
}
